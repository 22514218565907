import { ZodSchema, z } from "zod";
import {
  AccountPlan as PrismaAccountPlan,
  PaidFeature as PrismaPaidFeature,
} from "@prisma/client";

export type Operation = {
  type: string;
  path: string;
  validator: ZodSchema;
};

// When adding a new feature, add it to the PaidFeature enum in schema.prisma
export const paidFeatures = z.nativeEnum(PrismaPaidFeature);
export type PaidFeature = z.infer<typeof paidFeatures>;

export const accountPlan = z.nativeEnum(PrismaAccountPlan);
export type AccountPlan = z.infer<typeof accountPlan>;

export const premiumFeatures: PaidFeature[] = [
  "customColors",
  "branding",
  "embedding",
  "analytics",
  "customTokenLength",

  "passwordProtect",
  "managedUsers",

  "chatBotDataSources",
  "conditionalLogic",
  "fileSizeLimit10mb",
  "embedBlock",
  "layoutBlock",
  "stripePayment",

  /**
   * Stytch-related project authorization
   */
  "stytchAuth",
  "projectMagicLinkAuth",
];

export const advancedFeatures: PaidFeature[] = [
  ...premiumFeatures,
  "customDomain",
  "dynamicFilters",
  "hideZapierButton",
  "fileSizeLimit25mb",

  /**
   * Stytch-related project authorization
   */
  "projectSignInWithGoogleAuth",
  "projectApprovedDomainsAuth",
];

export const teamAccountFeatures: PaidFeature[] = ["collaborators"];

export const allFeatures: PaidFeature[] = Array.from(
  new Set(premiumFeatures.concat(advancedFeatures, teamAccountFeatures))
);
