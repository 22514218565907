import { z } from "zod";
import { validateEmail } from "./util";
import { AccountEntitlementsSchema } from "server/services/zapier/types/types";
import { paidFeatures } from "server/auth/types";

const RoleEnum = z.enum(["owner", "admin", "member", "super_admin"]);

export const RoleSchema = z.object({
  account_id: z.number().int(),
  role: RoleEnum,
});

export type Role = z.infer<typeof RoleSchema>;

export const UserSchema = z.object({
  id: z.string().cuid(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().refine(validateEmail),
  avatarUrl: z.string().url().nullable(),
  isStaff: z.boolean(),
});
export type User = z.infer<typeof UserSchema>;

export const MinimalUserSchema = z.object({
  id: z.string().cuid(),
  zapierId: z.number(),
  isStaff: z.boolean(),
});

export const AccountSchema = z.object({
  id: z.number(),
  name: z.string(),
  owner_id: z.number(),
  logo_url: z.string(),
  is_paid: z.boolean(),
  is_unlimited: z.boolean(),
  is_team_account: z.boolean(),
  is_company_account: z.boolean(),
});
export type Account = z.infer<typeof AccountSchema>;

export const accountPlan = z.enum(["free", "premium", "advanced"]);
export type AccountPlan = z.infer<typeof accountPlan>;

export const UserProfileSchema = z.object({
  id: z.string().cuid(),
  name: z.string(),
  zapierId: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  currentAccount: z.number(),
  currentUserRole: z
    .enum(["owner", "admin", "member", "super_admin"])
    .optional(),
  avatarUrl: z.string().url().nullable(),
  isStaff: z.boolean(),
  isAdmin: z.boolean(),
  createdAt: z.coerce.date(),
  entitlements: AccountEntitlementsSchema,
  paidFeatureAccess: z.array(paidFeatures).optional(),
  accountProjectLimit: z.number().optional(),
  planName: accountPlan.optional(),
});

export type UserProfile = z.infer<typeof UserProfileSchema>;

export const UserAuthenticationSchema = z.object({
  id: z.number().transform((v) => v.toString()),
  url: z.string().url(),
  title: z.string().nullable(),
  label: z.string(),
});

export type UserAuthentication = z.infer<typeof UserAuthenticationSchema>;

export const ZapierSessionSchema = z.union([
  z
    .object({
      id: z.number().int(),
      first_name: z.string(),
      last_name: z.string(),
      email: z.string(),
      email_confirmed: z.boolean(),
      current_account_id: z.number().int().nullable(),
      photo_url: z.string().nullable(),
      is_logged_in: z.literal(true),
      is_staff: z.boolean(),
      roles: z.array(RoleSchema),
      name: z.string().optional(),
    })
    .passthrough(),
  z
    .object({
      is_logged_in: z.literal(false),
    })
    .passthrough(),
]);

export type ZapierSession = z.infer<typeof ZapierSessionSchema>;

export const ZapierAccountsSchema = z
  .object({
    primary_id: z.number().int(),
    objects: z.array(AccountSchema).optional(),
  })
  .passthrough();

export type ZapierAccounts = z.infer<typeof ZapierAccountsSchema>;
