import Script from "next/script";

export function IntercomScript() {
  return (
    <Script
      src="https://cdn.zapier.com/packages/intercom/v1/index.js"
      strategy="afterInteractive"
    />
  );
}
