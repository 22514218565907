import { UploadcareImageSchema } from "server/schemas/projects";

export const DEFAULT_PROJECT_FAVICON = UploadcareImageSchema.parse({
  url: "https://zapier-images.imgix.net/storage/photos/c7ed9691c53b602419408c5eba12dd58.png",
  name: "interfaces-default-project-favicon.png",
});

export const DEFAULT_CHATBOT_FAVICON = UploadcareImageSchema.parse({
  url: "https://zapier.com/app/chatbots/public/favicon.ico",
  name: "interfaces-default-chatbot-favicon.png",
});
