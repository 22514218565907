import { ReactNode } from "react";
import { css, styled } from "lib/theme";
import { ColorsCSS as Colors, Shadows } from "@zapier/design-system";

const Vars = {
  spacing: "--spacing",
  footerMarginBottom: "--footer-margin-bottom",
};

// This is a copy of ModalContent from @zapier/design-system.
const Wrapper = styled.div<{ $width?: string; $height?: string }>`
  ${Vars.spacing}: 20px;
  ${Vars.footerMarginBottom}: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 70px);
  background-color: ${Colors.neutral100};
  padding: var(${Vars.spacing});
  // 40px handles bottom toolbar in iOS Safari
  margin: 10px 10px calc(10px + 40px);
  border-radius: 5px;
  border: 1px solid ${Colors.neutral300};
  box-shadow: ${Shadows.elevation5};
  overflow: auto;
  padding-bottom: 0;

  @media screen and (min-width: 650px) {
    // Effectively tighten the bottom padding of ModalContent
    // by pulling it up via margin.
    ${Vars.footerMarginBottom}: -10px;
    min-width: 550px;
    max-width: 100%;
    ${({ $width }) =>
      $width &&
      css`
        width: ${$width};
      `}
    ${({ $height }) =>
      $height &&
      css`
        height: ${$height};
      `}
    padding: calc(var(${Vars.spacing}) * 2);
    padding-bottom: 0;
    margin: 30px;
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

// A drop-in replacement for ModalContent that allows for custom sizing.
export function CustomModalContent(props: {
  children?: ReactNode;
  closeButton?: ReactNode;
  width?: string;
  height?: string;
  className?: string;
}) {
  return (
    <Wrapper
      className={props.className}
      $width={props.width}
      $height={props.height}
    >
      {props.children}
      <CloseButtonWrapper>{props.closeButton}</CloseButtonWrapper>
    </Wrapper>
  );
}
