import "zod-openapi/extend";

import { z } from "zod";
import { extendZodWithOpenApi } from "zod-openapi";

extendZodWithOpenApi(z);

export const tablePermissions = z
  .union([
    z.literal("add:fields"),
    z.literal("get:fields"),
    z.literal("update:fields"),
    z.literal("delete:fields"),
    z.literal("add:records"),
    z.literal("get:records"),
    z.literal("update:records"),
    z.literal("delete:records"),
    z.literal("add:config"),
    z.literal("get:config"),
    z.literal("update:config"),
    z.literal("add:field_configurations"),
    z.literal("update:field_configurations"),
  ])
  .array();

export type TablePermissions = z.infer<typeof tablePermissions>;

export const listTablesSchema = z.object({
  permissions: tablePermissions.optional(),
});

export type ListTablesInput = z.infer<typeof listTablesSchema>;

export const getTableSchema = z.object({
  tableId: z.string(),
});

export const getTableFiltersSchema = z.object({
  tableId: z.string(),
});

export const validateTableDeletionSchema = z.object({
  blockId: z.string(),
});

export const tableInterfacesInputSchema = z.object({
  tableId: z.string(),
});

export const tableInterfacesOutputSchema = z
  .object({
    interfacesProjectName: z.string(),
    interfacesProjectId: z.string(),
    interfacesProjectHref: z.string(),
    interfacesProjectOwnerCustomuserId: z.number(),
    interfacesProjectIsShared: z.boolean(),
    interfacesProjectAccountId: z.number(),
  })
  .array();

export const createTablesSchema = z.object({
  body: z.object({
    name: z.string(),
    description: z.string().optional(),
  }),
});

export const deleteTableSchema = z.object({
  tableId: z.string(),
  ignoreConflicts: z
    .boolean()
    .optional()
    .default(false)
    .openapi({ effectType: "input" }),
});

export const TableWithFieldsSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    fields: z.array(
      z
        .object({
          id: z.number(),
          name: z.string(),
          // TODO: replicate supported types from tables API?
          type: z.string(),
          options: z.record(z.any(), z.any()).optional(),
        })
        .openapi({ ref: "TableField" })
    ),
  })
  .openapi({ ref: "Table" });
