import { ReactNode } from "react";

import { styled } from "lib/theme";
import { ColorsCSS as Colors } from "@zapier/design-system";

const Wrapper = styled.div`
  background-color: var(--zds-background-weaker, ${Colors.neutral100});
  min-height: 100vh;
`;

export default function MainLayout(props: { children: ReactNode }) {
  return <Wrapper>{props.children}</Wrapper>;
}
