import React, { ReactNode } from "react";
import { Text, TypographyName } from "@zapier/design-system";

type InterfacesTextProps = typeof Text & {
  children?: ReactNode | null;
  className?: string;
  textType?: TypographyName;
};

const InterfacesText = ({
  className,
  children,
  textType,
  ...rest
}: InterfacesTextProps) => {
  return (
    <div className={className}>
      <Text type={textType} {...rest}>
        {children}
      </Text>
    </div>
  );
};

export default InterfacesText;
